import revive_payload_client_YTGlmQWWT5 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dRvYLh8oYc from "/builds/tra/webshop/frontend/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KMIg7cVUFv from "/builds/tra/webshop/frontend/src/tra/.nuxt-nl/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kpnqASteq8 from "/builds/tra/webshop/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkit_fields_UoilKtyqbN from "/builds/tra/webshop/frontend/src/tra/.nuxt-nl/formkit-fields.ts";
import recaptcha_Ch7DD5Xmsn from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/modules/10_recaptcha/runtime/plugins/recaptcha.ts";
import switch_locale_path_ssr_lCxu0ZmxIx from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_DtfAVtogpk from "/builds/tra/webshop/frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_errors_HdvRGZoXfB from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/apollo-errors.ts";
import apollo_persisted_queries_ttPjo3G20q from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/apollo-persisted-queries.ts";
import apollo_recaptcha_0NZ8S7c7VT from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/apollo-recaptcha.ts";
import interpolation_4O78SQECao from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/plugins/interpolation.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_dRvYLh8oYc,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KMIg7cVUFv,
  prefetch_client_8FoqvPFTot,
  plugin_kpnqASteq8,
  formkit_fields_UoilKtyqbN,
  recaptcha_Ch7DD5Xmsn,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  plugin_DtfAVtogpk,
  apollo_errors_HdvRGZoXfB,
  apollo_persisted_queries_ttPjo3G20q,
  apollo_recaptcha_0NZ8S7c7VT,
  interpolation_4O78SQECao
]