import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (currentRoutes) => {
    const { public: config } = useRuntimeConfig()

    // sadly no access to translations (useI18n)

    let routes = []

    switch (config.i18n.defaultLocale) {
      case 'en':
        routes = [
          { path: '/brands/', name: 'brands', component: () => import('~layers/app/pages/~brands.vue').then(r => r.default || r) },
        ]
        break
      case 'de':
        routes = [
          { path: '/marken/', name: 'brands', component: () => import('~layers/app/pages/~brands.vue').then(r => r.default || r) },
        ]
        break
      default:
        routes = [
          { path: '/merken/', name: 'brands', component: () => import('~layers/app/pages/~brands.vue').then(r => r.default || r) },
        ]
        break
    }

    return currentRoutes.concat(routes)
  },
}
