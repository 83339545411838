import type { EsProduct } from '~layers/app/types/elastic'
import type { MagentoCart, MagentoCartItem, MagentoGtmOrder } from '~layers/app/types/magento'

export type DataLayerEcomEvent = {
  coupon?: string
  currency: string
  payment_type?: string
  shipping?: number
  shipping_tier?: string
  tax?: number
  transaction_id?: string
  value?: number
  value_excluded_tax_shipping?: number
  customer?: {
    email?: string
    firstname?: string
    lastname?: string
    newsletter?: boolean
    account?: string
    phone?: string | null
    street?: string
    city?: string
    postcode?: string
    country?: string
  }
  category?: MagentoCategory
}

export type MagentoCategory = {
  id?: string
  name?: string
}

export type CustomerRegister = {
  firstname: string
  lastname: string
  is_subscribed?: string
  email: string
}

export type DataLayerItem = {
  affiliation?: string
  coupon?: string
  discount?: number
  item_name: string
  item_id: string
  price: number
  price_incl_tax: number
  item_size?: string
  item_type?: string
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_category6?: string
  item_category7?: string
  item_category8?: string
  item_category9?: string
  item_list_name?: string
  item_list_id?: string
  item_variant?: string
  index?: number
  item_review?: number
  is_item_in_stock?: number
  location_id?: string
  quantity?: number
  skus?: string[]
  sku?: string
}

const pageView = (pageType: string, pageTitle?: string) => {
  const gtm = useGtm()
  const route = useRoute()
  onMounted(() => {
    watch(
      () => route.href,
      (href) => {
        gtm?.push({
          event: 'pageView',
          pageType,
          pageUrl: href,
          pageTitle: pageTitle || pageType,
        })
      },
      { immediate: true },
    )
  })
}

const formatGa4Item = (item: { product: EsProduct, overrides?: Partial<DataLayerItem> }): DataLayerItem => {
  const { product, overrides } = item
  const result: DataLayerItem = {
    item_name: product.name,
    item_id: product.sku,
    price: product.finalPrice || 0,
    item_variant: product.color?.label || '',
    item_brand: product.brand?.label || '',
    is_item_in_stock: product.stockStatus ? 1 : 0,
  }
  let categories = [...(product.categories || [])]
    .sort((a, b) => a.level - b.level)
  if (categories.length > 0) {
    const deepestPath = categories?.[categories.length - 1]?.path
    categories = categories.filter(category => deepestPath?.startsWith(category.path))
    let index = 1
    for (const category of categories) {
      result['item_category' + (index === 1 ? '' : index)] = category.name
      index++
    }
  }
  return { ...result, ...overrides }
}

const ecomGa4ItemEvent = (
  event: string,
  ecomEventData: DataLayerEcomEvent,
  items: { product: EsProduct, overrides?: Partial<DataLayerItem> }[],
) => {
  const gtm = useGtm()
  gtm?.push({ ecommerce: null })
  gtm?.push({
    event,
    ecommerce: {
      ...ecomEventData,
      items: items.map(
        ({ product, overrides }, index) => formatGa4Item({
          product,
          overrides: { index, ...overrides },
        }),
      ),
    },
  })
}

export default () => {
  const { locale, locales } = useI18n({ useScope: 'global' })

  const currency = locales.value.find(lcl => lcl.code === locale.value)?.numberFormats.currency.currency || 'EUR'

  const addToWishList = (product: EsProduct, overrides?: Partial<DataLayerItem>) => {
    ecomGa4ItemEvent('add_to_wishlist', { currency }, [{ product, overrides: { quantity: 1, ...overrides } }])
  }

  const selectItem = (product: EsProduct, overrides?: Partial<DataLayerItem>) => {
    ecomGa4ItemEvent('select_item', { currency }, [{ product, overrides }])
  }

  const viewItem = (product: EsProduct, overrides?: Partial<DataLayerItem>) => {
    ecomGa4ItemEvent('view_item', { currency }, [{ product, overrides }])
  }

  const viewItemList = (products: EsProduct[], overrides?: Partial<DataLayerItem>, category?: Partial<MagentoCategory>) => {
    ecomGa4ItemEvent('view_item_list', { currency, category }, products.map(product => ({ product, overrides })))
  }

  const mapCart = (cart: MagentoCart, overrides?: Partial<DataLayerEcomEvent>): DataLayerEcomEvent => {
    const coupon = (cart.applied_coupons || []).map(coupon => coupon.code).join(',')
    const selectedShippingMethod = cart.shipping_addresses?.[0]?.selected_shipping_method
    const shippingTier = selectedShippingMethod ? `${selectedShippingMethod.carrier_title} ${selectedShippingMethod.method_title}` : ''
    const customer = billingAddress.value
      ? {
          email: email.value,
          firstname: billingAddress.value?.firstname,
          lastname: billingAddress.value?.lastname,
          street: billingAddress.value?.street?.[0] + ' ' + billingAddress.value?.street?.[1],
          city: billingAddress.value?.city,
          country: billingAddress.value?.country?.code,
          postcode: billingAddress.value?.postcode,
          phone: billingAddress.value?.telephone,
          newsletter: subscribe.value,
        }
      : undefined
    return {
      currency,
      ...(coupon ? { coupon } : {}),
      ...(shippingTier ? { shipping_tier: shippingTier } : {}),
      ...{ customer: customer },
      value: cart.prices.grand_total.value,
      ...overrides,
    }
  }

  const mapCartItem = (item: MagentoCartItem): { product: EsProduct, overrides?: Partial<DataLayerItem> } => ({
    product: item.product.product,
    overrides: {
      quantity: item.quantity,
      item_size: item.configurable_options?.[0]?.value_label || 'one-size',
      sku: item.configured_variant ? item.configured_variant.sku : item.product.sku,
    },
  })
  const viewCart = (cart: MagentoCart, overrides?: Partial<DataLayerEcomEvent>) => {
    ecomGa4ItemEvent('view_cart', mapCart(cart, overrides), cart.items.map(mapCartItem))
  }

  const addToCart = (cartItems: MagentoCartItem[]) => {
    const items = cartItems.map(mapCartItem)
    ecomGa4ItemEvent('add_to_cart', { currency }, items)
  }

  const removeFromCart = (cartItem: MagentoCartItem) => {
    const item = mapCartItem(cartItem)
    ecomGa4ItemEvent('remove_from_cart', { currency }, [item])
  }

  const beginCheckout = (cart: MagentoCart, overrides?: Partial<DataLayerEcomEvent>) => {
    ecomGa4ItemEvent('begin_checkout', mapCart(cart, overrides), cart.items.map(mapCartItem))
  }

  const addShippingInfo = (cart: MagentoCart, overrides?: Partial<DataLayerEcomEvent>) => {
    ecomGa4ItemEvent('add_shipping_info', mapCart(cart, overrides), cart.items.map(mapCartItem))
  }

  const addPaymentInfo = (cart: MagentoCart, overrides?: Partial<DataLayerEcomEvent>) => {
    ecomGa4ItemEvent('add_payment_info', mapCart(cart, overrides), cart.items.map(mapCartItem))
  }

  const purchase = (gtmOrder: MagentoGtmOrder) => {
    ecomGa4ItemEvent(
      'purchase',
      {
        ...(gtmOrder.purchase.actionField.coupon ? { coupon: gtmOrder.purchase.actionField.coupon } : {}),
        currency,
        payment_type: gtmOrder.purchase.actionField.payment_type || undefined,
        shipping: gtmOrder.purchase.actionField.shipping,
        shipping_tier: gtmOrder.purchase.actionField.shipping_tier || undefined,
        tax: gtmOrder.purchase.actionField.tax,
        transaction_id: gtmOrder.purchase.actionField.id,
        value: gtmOrder.purchase.actionField.revenue,
        value_excluded_tax_shipping: gtmOrder.purchase.products.reduce((acc, product) => acc + product.price * product.quantity, 0),
        customer: {
          email: gtmOrder.customer.email,
          firstname: gtmOrder.customer.firstname,
          lastname: gtmOrder.customer.lastname,
          street: gtmOrder.customer.street,
          city: gtmOrder.customer.city,
          country: gtmOrder.customer.country,
          postcode: gtmOrder.customer.postcode,
          phone: gtmOrder.customer.phone,
          newsletter: !!gtmOrder.customer.newsletter,
        },
      },
      gtmOrder.purchase.products.map((product, index): { product: EsProduct, overrides?: Partial<DataLayerItem> } => ({
        product: product.product,
        overrides: {
          ...(product.coupon ? { coupon: product.coupon } : {}),
          index,
          // ...(product.variant ? { item_variant: product.variant } : {}),
          price: product.price_incl_tax,
          quantity: product.quantity,
        },
      })),
    )
  }

  const cookiePreference = () => {
    const gtm = useGtm()
    gtm?.push({ event: 'cookiePreference' })
  }

  const newsletterSubscription = (email: string) => {
    const gtm = useGtm()
    gtm?.push({ event: 'email_optin', customer: { email: email } })
  }

  const accountLogin = (email: string) => {
    const gtm = useGtm()
    gtm?.push({ event: 'accountLogin', customer: { email: email } })
  }

  const completeRegistration = (customer: CustomerRegister) => {
    const gtm = useGtm()
    gtm?.push({
      event: 'completeRegistration', customer: {
        firstname: customer.firstname,
        lastname: customer.lastname,
        newsletter: customer.is_subscribed ? 'yes' : 'no',
        email: customer.email,
      },
    })
  }

  const { email, billingAddress } = useCart()
  const { subscribe } = useNewsletter()

  return {
    accountLogin,
    addPaymentInfo,
    addShippingInfo,
    addToCart,
    addToWishList,
    beginCheckout,
    completeRegistration,
    cookiePreference,
    newsletterSubscription,
    pageView,
    purchase,
    removeFromCart,
    selectItem,
    viewCart,
    viewItem,
    viewItemList,
  }
}
