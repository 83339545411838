<template>
  <div class="app">
    <NuxtLayout class="app__layout">
      <NuxtPage class="app__template" />
    </NuxtLayout>

    <ThirdpartyLoader :loadtime="7500" />

    <LazyDevtools />
  </div>
</template>

<script lang="ts" setup>
// Added this to handle removal of utm_* parameters by varnish
// see https://github.com/nuxt/nuxt/issues/23153
await useAsyncData('data',
  async (nuxtApp) => {
    delete nuxtApp?.payload?.path
    return {}
  },
)

const { public: config } = useRuntimeConfig()
const { t } = useI18n({ useScope: 'global' })

const { query } = useRoute()
const { locale } = useI18n()

useHead({
  title: '',
  titleTemplate: '%s | ' + t('nuxtSiteConfig.name'),
  htmlAttrs: {
    lang: locale.value,
  },
  meta: [
    { hid: 'description', name: 'description', content: '' },
    { hid: 'format-detection', name: 'format-detection', content: 'telephone=no' },
    { hid: 'msapplication-TileColor', name: 'msapplication-TileColor', content: '#16002b' },
    { hid: 'theme-color', name: 'theme-color', content: '#ffffff' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'manifest', href: '/site.webmanifest' },
    { rel: 'mask-icon', type: 'image/x-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'preconnect', href: config.xcdnUrl },
  ],
})

/* Disable thirdparty scripts/widgets to test application performnce in for example Google Lighthouse */
if (query.thirdparty !== 'disabled' && config?.gtm?.id) {
  useServerHead({
    noscript: [
      {
        tagPosition: 'bodyOpen',
        innerHTML: `<iframe src="${config?.gtm?.source?.replace('/gtm.js', '/ns.html') || 'https://www.googletagmanager.com/ns.html'}?id=${config.gtm.id}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      },
    ],
    script: [
      {
        tagPriority: 101,
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=${config?.gtm?.defer || true};j.src=
'${config?.gtm?.source || 'https://www.googletagmanager.com/gtm.js'}?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${config.gtm.id}');`,
      },
      // {
      //   tagPriority: 101,
      //   innerHTML: `window.dataLayer=(window.dataLayer||[]);window.dataLayer.push({'event':'gtm.js','gtm.start': new Date().getTime(),});`,
      // },
      // {
      //   tagPriority: 101,
      //   src: `${config?.gtm?.source || 'https://www.googletagmanager.com/gtm.js'}?id=${config.gtm.id}`,
      //   defer: config?.gtm?.defer || true,
      // },
    ],
  })
}

await useStore()
useCart()
</script>

<style src="~layers/app/assets/css/vars.css" />

<style src="~layers/app/assets/css/tokens/colors.css" />

<style src="~layers/app/assets/css/base/reset.css" />

<style src="~layers/app/assets/css/base/root-and-sectioning.css" />

<style src="~layers/app/assets/css/base/forms-and-inputs.css" />

<style src="~layers/app/assets/css/base/forms-and-inputs__file.css" />

<style src="~layers/app/assets/css/base/forms-and-inputs__color.css" />

<style src="~layers/app/assets/css/base/forms-and-inputs__range.css" />

<style src="~layers/app/assets/css/base/forms-and-inputs__select.css" />

<style src="~layers/app/assets/css/base/forms-and-inputs__checkbox-radio.css" />

<style src="~layers/app/assets/css/base/text-content.css" />

<style src="~layers/app/assets/css/base/text-content__headings.css" />

<style src="~layers/app/assets/css/base/text-content__anchor-button.css" />

<style src="~layers/app/assets/css/base/text-content__kbd.css" />

<style src="~layers/app/assets/css/base/text-content__ruby.css" />

<style src="~layers/app/assets/css/base/multimedia-and-embeds.css" />

<style src="~layers/app/assets/css/base/lists.css" />

<style src="~layers/app/assets/css/base/tables.css" />

<style src="~layers/app/assets/css/base/details-summary.css" />

<style src="~layers/app/assets/css/base/dialog-popover-backdrop.css" />

<style src="~layers/app/assets/css/base/meter.css" />

<style src="~layers/app/assets/css/base/progress.css" />

<style src="~layers/app/assets/css/styleguide/_difference.css" />

<style src="~layers/app/assets/css/utilities/utilities.css" />

<style src="~layers/app/assets/css/utilities/buttons.css" />

<style src="~layers/app/assets/css/utilities/messages.css" />

<style src="~layers/app/assets/css/utilities/tables.css" />

<style src="~layers/app/assets/css/vue-formkit.css" />

<style src="~layers/app/app.css" />
