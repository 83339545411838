import redirectResolverQuery from '~layers/app/graphql/routes/redirectResolver.gql'
import esProductsBySearchQuery from '~layers/app/graphql/routes/esProductsBySearch.gql'

export default function useCustomRouterFetchRedirect() {
  const { client } = useApolloClient('default')

  const fetchRedirect = async (path: string) => {
    const result = await client.query<any>({
      query: redirectResolverQuery,
      variables: { url: path },
    })
    return result?.data?.redirectResolver
  }

  const fetchPdpRedirect = async (url: string) => {
    const result = await client.query<any>({
      query: esProductsBySearchQuery,
      variables: { search: url },
    })
    return result?.data?.esUrlByAlternativeUrl?.url
  }

  return {
    fetchRedirect,
    fetchPdpRedirect,
  }
}
