
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93vUgv6vujf0Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/[...slug].vue?macro=true";
import { default as _126blog_45detailNmzX3BO7EbMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/~blog-detail.vue?macro=true";
import { default as _126brandsSTF2mi5CRPMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/~brands.vue?macro=true";
import { default as _126cmsUOvkEGhjuVMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/~cms.vue?macro=true";
import { default as _126pdpE441POxBvqMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/~pdp.vue?macro=true";
import { default as createc0TvTpJr2zMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/create.vue?macro=true";
import { default as _91id_93m2ZiqdkBFjMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/edit/[id].vue?macro=true";
import { default as index6FpTNaAXdqMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/index.vue?macro=true";
import { default as editzML7HZ8mTZMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/edit.vue?macro=true";
import { default as indexDqmeAarNd2Meta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/index.vue?macro=true";
import { default as newsletterrtdxTue6yZMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/newsletter.vue?macro=true";
import { default as _91id_93nUBBvDnqVgMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/[id].vue?macro=true";
import { default as indexlplRY793HuMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/index.vue?macro=true";
import { default as accounthpcMc87BL7Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/account.vue?macro=true";
import { default as carteiIRrxOfddMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/cart.vue?macro=true";
import { default as indexTxgTi18xlNMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout/index.vue?macro=true";
import { default as payment2MGlSzF8K0Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout/payment.vue?macro=true";
import { default as shipmentgn3bSVo3e1Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout/shipment.vue?macro=true";
import { default as checkout77KTVGgzrSMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/checkout.vue?macro=true";
import { default as kiosknW4HN8MAtCMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/config/kiosk.vue?macro=true";
import { default as create_45passwordz8mZhNbPQ4Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/create-password.vue?macro=true";
import { default as empty_45layoutkSNmTE5QWjMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/pages/empty-layout.vue?macro=true";
import { default as forgot_45passwordBcdpNhK2aLMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/forgot-password.vue?macro=true";
import { default as indexrUElkEhyJhMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/index.vue?macro=true";
import { default as previewPmV8mmhCayMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-strapi/src/app/pages/preview.vue?macro=true";
import { default as registerHr8IZpCmkQMeta } from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/register.vue?macro=true";
import { default as reset_45passwordskXsf0eBrGMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/reset-password.vue?macro=true";
import { default as searchwPXby6b6pWMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/search.vue?macro=true";
import { default as successsoP959rFVPMeta } from "/builds/tra/webshop/frontend/src/tra/app/pages/success.vue?macro=true";
import { default as wishlistWurMc3erN1Meta } from "/builds/tra/webshop/frontend/src/tra/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/[...slug].vue")
  },
  {
    name: "~blog-detail",
    path: "/~blog-detail",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/~blog-detail.vue")
  },
  {
    name: "~brands",
    path: "/~brands",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/~brands.vue")
  },
  {
    name: "~cms",
    path: "/~cms",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/~cms.vue")
  },
  {
    name: "~pdp",
    path: "/~pdp",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/~pdp.vue")
  },
  {
    name: accounthpcMc87BL7Meta?.name,
    path: "/account",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses-create",
    path: "addresses/create",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/create.vue")
  },
  {
    name: "account-addresses-edit-id",
    path: "addresses/edit/:id()",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/edit/[id].vue")
  },
  {
    name: "account-addresses",
    path: "addresses",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-edit",
    path: "edit",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/edit.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/index.vue")
  },
  {
    name: "account-newsletter",
    path: "newsletter",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/newsletter.vue")
  },
  {
    name: "account-orders-id",
    path: "orders/:id()",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/account/orders/index.vue")
  }
]
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/cart.vue")
  },
  {
    name: checkout77KTVGgzrSMeta?.name,
    path: "/checkout",
    meta: checkout77KTVGgzrSMeta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout.vue"),
    children: [
  {
    name: "checkout",
    path: "",
    meta: indexTxgTi18xlNMeta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment",
    path: "payment",
    meta: payment2MGlSzF8K0Meta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout/payment.vue")
  },
  {
    name: "checkout-shipment",
    path: "shipment",
    meta: shipmentgn3bSVo3e1Meta || {},
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/checkout/shipment.vue")
  }
]
  },
  {
    name: "config-kiosk",
    path: "/config/kiosk",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/config/kiosk.vue")
  },
  {
    name: "create-password",
    path: "/create-password",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/create-password.vue")
  },
  {
    name: "empty-layout",
    path: "/empty-layout",
    meta: empty_45layoutkSNmTE5QWjMeta || {},
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/pages/empty-layout.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/index.vue")
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-strapi/src/app/pages/preview.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-magento2/src/app/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/reset-password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/search.vue")
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/success.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/builds/tra/webshop/frontend/src/tra/app/pages/wishlist.vue")
  }
]