import routerOptions0 from "/builds/tra/webshop/frontend/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/app/router.options.ts";
import routerOptions2 from "/builds/tra/webshop/frontend/src/tra/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions1.hashMode ?? routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
...routerOptions2,
}